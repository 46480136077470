import { useParams } from "react-router-dom";
import { Card, CardContent } from '@mui/material';
import MDBox from "mdpr2/components/MDBox";
import useMounted from "hooks/use-mounted";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import MDTypography from "mdpr2/components/MDTypography";


import { resourceApi } from "api/resource-api";
import LearningGroupConfiguration from "../LearningGroupConfiguration";
import MisTeachers from "../MisTeachers";

const InfininityConfiguration = () => {
  const [learningGroup, setLearningGroup] = useState(null);

  const params = useParams();
  const id = params.id ? Number(params.id) : null;

  const getResource = async () => {
    try {
      const apiResource = await resourceApi.getResource({
        apiEndpoint: 'learning-groups',
        id
      });
      setLearningGroup(apiResource);

    } catch (error) {
      toast.error(error.toString());
    }
  }

  const isMounted = useMounted();
  useEffect(() => {
    getResource();
  }, [isMounted]);


  return (
        <>
        {learningGroup && (
         <MDBox m={2}>
         <MDTypography variant="h5">{learningGroup.name}</MDTypography>
         </MDBox>)}
         {id && (
          <>
          <MDBox m={2}>
            <Card>
              <CardContent>
                <LearningGroupConfiguration learningGroupId={id} />
              </CardContent>
            </Card>
          </MDBox>
          <MDBox m={2}>
            <Card>
              <CardContent>
                <MisTeachers learningGroupId={id}/>
              </CardContent>
            </Card>
          </MDBox>
          </>
         )}
        </>
        )
};


export default InfininityConfiguration;

// WRM
import ListResources from 'components/shared/ListResources';

const title = 'Product reporting categories';
const apiEndpoint = 'product-reporting-categories';
const resourceName = 'product reporting category';

const columns = [
  {
    field: 'name',
    label: 'Name',
    sortable: true,
    flex: 1,
  },
  {
    field: 'parentProductReportingCategory',
    label: 'Parent category',
    flex: 1,
    formatter: (resource) => resource.parentProductReportingCategory?.name,
  },
];

const filters = [
  {
    field: 'name',
    label: 'Name',
    type: 'text',
  },
  {
    field: 'canHaveProductsAssigned',
    label: 'Products can be assigned',
    type: 'checkbox',
  },
];

const defaultSortValues = [
  {
    field: 'name',
    direction: 'asc',
  },
];

const ProductReportingCategories = () => (
  <ListResources
    apiEndpoint={apiEndpoint}
    columns={columns}
    defaultSortValues={defaultSortValues}
    filters={filters}
    resourceName={resourceName}
    title={title}
  />
);

export default ProductReportingCategories;
